export const autoForm = {
  GET_AUTO_FORM_REQUEST: "GET_AUTO_FORM_REQUEST",
  GET_AUTO_FORM_SUCCESS: "GET_AUTO_FORM_SUCCESS",
  GET_AUTO_FORM_FAIL: "GET_AUTO_FORM_FAIL",
  ADD_AUTO_REQUEST: "ADD_AUTO_REQUEST",
  ADD_AUTO_SUCCESS: "ADD_AUTO_SUCCESS",
  ADD_AUTO_FAIL: "ADD_AUTO_FAIL",
  DELETE_AUTO_REQUEST: "DELETE_AUTO_REQUEST",
  DELETE_AUTO_SUCCESS: "DELETE_AUTO_SUCCESS",
  DELETE_AUTO_FAIL: "DELETE_AUTO_FAIL",
  UPDATE_AUTO_REQUEST: "UPDATE_AUTO_REQUEST",
  UPDATE_AUTO_SUCCESS: "UPDATE_AUTO_SUCCESS",
  UPDATE_AUTO_FAIL: "UPDATE_AUTO_FAIL"
};
