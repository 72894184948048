import {
  isContainsLowercase,
  isContainsNumber,
  isContainsSymbol,
  isContainsUppercase,
  isValidLength
} from "../utils/regex";
import ErrorMSG from "./ErrorMSG";

export function PasswordValidation({ value }) {
  return (
    <ErrorMSG
      text={
        <>
          Password at least <span className={isContainsUppercase.test(value) ? "text-success" : ""}>1 uppercase,</span>{" "}
          <span className={isContainsLowercase.test(value) ? "text-success" : ""}>1 lowercase,</span>{" "}
          <span className={isContainsSymbol.test(value) ? "text-success" : ""}>1 special character,</span>{" "}
          <span className={isContainsNumber.test(value) ? "text-success" : ""}>1 number,</span>{" "}
          <span className={isValidLength.test(value) ? "text-success" : ""}>8-32 Characters Long</span>
        </>
      }
    />
  );
}
