import { BlogsType } from "./type";

//Blogs state
const initialAuthState = {
  blogs: null
};

export const reducer = (state = initialAuthState, action) => {
  switch (action.type) {
    case BlogsType.BLOGS_SUCCESS:
      return { ...state, blogs: action.payload.data };
    default:
      return state;
  }
};
