import { Box, Button, Paper } from "@mui/material";

export const TestForm = () => {
  return (
    <div>
      <Box width="100%" backgroundColor="#fff" p="2rem" className="sectionPad">
        <Paper sx={{ padding: 2 }}>
          <form action="#" method="post" id="testform" enctype="multipart/form-data">
            <label htmlFor="firstName">First Name:</label>
            <input type="text" id="firstName" name="firstName" required placeholder="Enter your first name here..." />
            <br></br>
            <label htmlFor="lastName">Last Name:</label>
            <input type="text" id="lastName" name="lastName" required placeholder="Enter your Last name here..." />
            <br></br>
            <label htmlFor="email">email:</label>
            <input type="email" id="email" name="email" required placeholder="Enter your email name here..." />
            <br></br>
            <label htmlFor="phone">Phone:</label>
            <input type="tel" id="phone" name="phone" placeholder="Enter your Phone here..." required />
            <label htmlFor="phone">DatePicker</label>
            <input type="date" id="phone" name="phone" placeholder="Enter your Phone here..." required />
            <br></br>
            <label htmlFor="otp">OTP:</label>
            <input type="text" id="otp" name="otp" required placeholder="Enter your OTP here..." />
            <br></br>
            <label htmlFor="otp">checkbox</label>
            <div className="radiocheck">
              <input type="checkbox" id="contactUsNo" name="contactUs" value="no" />
              <label htmlFor="contactUsNo">black</label>
            </div>
            <div className="radiocheck">
              <input type="checkbox" id="contactUsNo" name="contactUs" value="no" />
              <label htmlFor="contactUsNo">white</label>
            </div>
            <br></br>
            <label htmlFor="subject">Subject:</label>
            <select id="testselect">
              <option>select...</option>
              <option>select</option>
              <option>select</option>
            </select>
            <br></br>
            <div className="radiocheck">
              <label className="switch">
                <input type="checkbox" />
                <span className="slider"></span>
              </label>
              <div>
                <label>Switch</label>
                <span>description</span>
              </div>
            </div>
            <div className="date-picker-container">
              <input type="text" id="datepicker" placeholder="Select a date" />
              <svg
                className="h-20px w-20px react-datepicker__calendar-icon "
                width="25px"
                height="25px"
                color="#141414"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 -960 960 960"
              >
                <path
                  fill="currentColor"
                  d="M480-400q-17 0-28.5-11.5T440-440q0-17 11.5-28.5T480-480q17 0 28.5 11.5T520-440q0 17-11.5 28.5T480-400Zm-160 0q-17 0-28.5-11.5T280-440q0-17 11.5-28.5T320-480q17 0 28.5 11.5T360-440q0 17-11.5 28.5T320-400Zm320 0q-17 0-28.5-11.5T600-440q0-17 11.5-28.5T640-480q17 0 28.5 11.5T680-440q0 17-11.5 28.5T640-400ZM480-240q-17 0-28.5-11.5T440-280q0-17 11.5-28.5T480-320q17 0 28.5 11.5T520-280q0 17-11.5 28.5T480-240Zm-160 0q-17 0-28.5-11.5T280-280q0-17 11.5-28.5T320-320q17 0 28.5 11.5T360-280q0 17-11.5 28.5T320-240Zm320 0q-17 0-28.5-11.5T600-280q0-17 11.5-28.5T640-320q17 0 28.5 11.5T680-280q0 17-11.5 28.5T640-240ZM180-80q-24 0-42-18t-18-42v-620q0-24 18-42t42-18h65v-60h65v60h340v-60h65v60h65q24 0 42 18t18 42v620q0 24-18 42t-42 18H180Zm0-60h600v-430H180v430Z"
                ></path>
              </svg>
            </div>
            <label htmlFor="comment">Comment:</label>
            <textarea
              id="comment"
              name="comment"
              rows="4"
              cols="50"
              required
              placeholder="Enter your Comment here..."
            ></textarea>
            <br></br>
            <label htmlFor="attachment">attachments</label>
            <input type="file" id="attachment" name="attachment" />
            <br></br>
            <Button variant="contained" href="#contained-buttons" className="submit">
              Submit
            </Button>
          </form>
        </Paper>
      </Box>
    </div>
  );
};
