import { useEffect, useState } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../../theme";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import SVG1 from "../../media/images/01.png";
import { shallowEqual, useSelector } from "react-redux";
import {
  ArtstIcon,
  ReportIcon,
  CheckInIcon,
  CheckSingleIcon,
  CloseIcon,
  EventListIcon,
  PostEventIcon,
  UserIcon,
  AgeGroupIcon,
  TermsIcon,
  SupportIcon,
  CustomerIcon,
  SponsorIcon,
  DiscountIcon,
  DashboardIcon,
  BankIcon,
  TicketSell,
  ReportIconeNew,
  Ticket,
  Activity,
  ReportTicket,
  InvoiceIcon
} from "../../components/Icons";
import { NotificationAddOutlined, RssFeedOutlined } from "@mui/icons-material";

const Item = ({ title, to, icon, state, toggled, handleToggleSidebar, isActive }) => {
  const location = useLocation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <MenuItem
      active={isActive?.findIndex((x) => x === location.pathname) > -1}
      style={{
        color: colors.grey[100],
        fontSize: "16px"
      }}
      icon={icon}
      onClick={() => {
        localStorage.setItem("ticketType", JSON.stringify(""));
        handleToggleSidebar(!toggled);
      }}
    >
      <Typography>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};

const Sidebar = ({ collapsed, toggled, handleToggleSidebar, handleCollapsedChange }) => {
  const location = useLocation();
  const theme = useTheme();
  const [searchParams] = useSearchParams();
  const colors = tokens(theme.palette.mode);
  // const isSmall = useMediaQuery(theme.breakpoints.down("sm") || theme.breakpoints.down("xs"));
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [tempToggleId, setTempToggleId] = useState("");
  const [selected, setSelected] = useState("Dashboard");
  const { user } = useSelector((state) => state.auth);
  const { toggledId, singleEvent } = useSelector((state) => state.events, shallowEqual);

  const WINDOW_USER = singleEvent?.eventInUser?.includes("window");
  const CHECKER_USER = singleEvent?.eventInUser?.includes("checker");

  useEffect(() => {
    setTempToggleId(window.location.pathname.split("/"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggledId, window.location.pathname]);

  return (
    <Box
      sx={{
        "& .pro-sidebar": {
          boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"
        },
        "& .pro-sidebar-inner": {
          background: "#eef2ff !important"
          // background: `${colors.grayBg[900]} !important`
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important"
        },
        "& .pro-inner-item": {
          padding: "5px 20px 5px 20px !important"
        },
        "& .pro-inner-item:hover, .pro-inner-item:hover .pro-icon svg": {
          color: `${colors.warning[900]} !important`
        },
        "& .pro-menu-item.active": {
          color: `${colors.warning[900]} !important`,
          background: "rgba(243, 133, 62, 0.1)"
        },
        "& .pro-menu-item.active svg": {
          color: `${colors.warning[900]} !important`
        },
        height: "100vh"
      }}
    >
      <ProSidebar collapsed={isCollapsed} breakPoint="lg" toggled={toggled} onToggle={handleToggleSidebar}>
        <Menu iconShape="square">
          {/* LOGO AND MENU ICON */}
          <MenuItem
            onClick={() => (window.innerWidth < 991 ? handleToggleSidebar(false) : setIsCollapsed(!isCollapsed))}
            icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
            style={{
              margin: "10px 0 20px 0",
              color: colors.grey[100]
            }}
          >
            {!isCollapsed && (
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <img src={SVG1} alt="" width={175} />
                {window.innerWidth < 991 ? (
                  <IconButton onClick={() => handleToggleSidebar(false)}>
                    <CloseIcon color={colors.iconGrey[900]} />
                  </IconButton>
                ) : (
                  <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                    <MenuOutlinedIcon />
                  </IconButton>
                )}
              </Box>
            )}
          </MenuItem>

          <Box>
            <Typography variant="h6" color={colors.grey[300]} sx={{ m: "15px 0 5px 20px" }}>
              Modules
            </Typography>
            {(user?.userType === "organizer" || user?.userType === "admin") && (
              <Item
                title="Dashboard"
                to="/dashboard"
                isActive={[`/`, `/dashboard`]}
                icon={<DashboardIcon />}
                selected={selected}
                setSelected={setSelected}
                handleToggleSidebar={handleToggleSidebar}
                toggled={toggled}
              />
            )}
            {(user?.userType === "organizer" || user?.userType === "admin") && (
              <Item
                title="List Of Events"
                to="/events"
                isActive={[`/events`]}
                icon={<EventListIcon />}
                selected={selected}
                setSelected={setSelected}
                handleToggleSidebar={handleToggleSidebar}
                toggled={toggled}
              />
            )}
            {(user?.userType === "checker" || user?.userType === "window") && (
              <Item
                title="List Of Events"
                to="/events"
                isActive={[`/`, `/events`]}
                icon={<EventListIcon />}
                selected={selected}
                setSelected={setSelected}
                handleToggleSidebar={handleToggleSidebar}
                toggled={toggled}
              />
            )}

            {user?.userType === "organizer" && (
              <Item
                title="Create Events"
                to="/create-event"
                isActive={[
                  `/create-event`,
                  `/create-event/${
                    tempToggleId?.length > 0 ? tempToggleId[tempToggleId[1] === "create-event" ? 2 : 3] : ""
                  }`
                ]}
                icon={<PostEventIcon />}
                selected={selected}
                setSelected={setSelected}
                handleToggleSidebar={handleToggleSidebar}
                toggled={toggled}
              />
            )}
            {user.userType === "admin" && (
              <>
                <Item
                  title="Organizers"
                  to="/organizers"
                  isActive={[
                    `/organizers`,
                    `/organizers/${
                      tempToggleId?.length > 0 ? tempToggleId[tempToggleId[1] === "organizers" ? 2 : 3] : ""
                    }`
                  ]}
                  icon={<UserIcon />}
                  selected={selected}
                  setSelected={setSelected}
                  handleToggleSidebar={handleToggleSidebar}
                  toggled={toggled}
                />
                <Item
                  title="Artists"
                  to="/artists"
                  isActive={[`/artists`]}
                  icon={<ArtstIcon />}
                  selected={selected}
                  setSelected={setSelected}
                  handleToggleSidebar={handleToggleSidebar}
                  toggled={toggled}
                />
                <Item
                  title="Customers"
                  to="/customers"
                  isActive={[
                    `/customers`,
                    `/customers/${
                      tempToggleId?.length > 0 ? tempToggleId[tempToggleId[1] === "customers" ? 2 : 3] : ""
                    }`
                  ]}
                  icon={<CustomerIcon />}
                  selected={selected}
                  setSelected={setSelected}
                  handleToggleSidebar={handleToggleSidebar}
                  toggled={toggled}
                />
                <Item
                  title="Terms and Conditions"
                  to="/term-condition"
                  isActive={[`/term-condition`]}
                  icon={<TermsIcon />}
                  selected={selected}
                  setSelected={setSelected}
                  handleToggleSidebar={handleToggleSidebar}
                  toggled={toggled}
                />

                <Item
                  title="Support"
                  to="/support"
                  isActive={[
                    `/support`,
                    `/support/${tempToggleId?.length > 0 ? tempToggleId[tempToggleId[1] === "support" ? 2 : 3] : ""}`
                  ]}
                  icon={<SupportIcon />}
                  selected={selected}
                  setSelected={setSelected}
                  handleToggleSidebar={handleToggleSidebar}
                  toggled={toggled}
                />
              </>
            )}
            {(user?.userType === "organizer" || user?.userType === "admin") && (
              <Item
                title="Advance Report"
                to={`transaction`}
                isActive={[`/transaction`]}
                icon={<ReportIcon />}
                selected={selected}
                setSelected={setSelected}
                handleToggleSidebar={handleToggleSidebar}
                toggled={toggled}
              />
            )}
            {(user?.userType === "organizer" || user?.userType === "admin") && (
              <Item
                title="Age Group Report"
                to={`/age-report`}
                isActive={[`/age-report`]}
                icon={<AgeGroupIcon />}
                selected={selected}
                setSelected={setSelected}
                handleToggleSidebar={handleToggleSidebar}
                toggled={toggled}
              />
            )}
            {user?.userType === "admin" && (
              <Item
                title="Notify Customers"
                to={`/notify`}
                isActive={[`/notify`]}
                icon={<NotificationAddOutlined />}
                selected={selected}
                setSelected={setSelected}
                handleToggleSidebar={handleToggleSidebar}
                toggled={toggled}
              />
            )}
            {user?.userType === "admin" && (
              <Item
                title="Invoice"
                to={`/invoice`}
                isActive={[`/invoice`]}
                icon={<InvoiceIcon />}
                selected={selected}
                setSelected={setSelected}
                handleToggleSidebar={handleToggleSidebar}
                toggled={toggled}
              />
            )}
            {user?.userType === "organizer" && (
              <Item
                title="Bills"
                to={`/bills`}
                isActive={[`/bills`]}
                icon={<InvoiceIcon />}
                selected={selected}
                setSelected={setSelected}
                handleToggleSidebar={handleToggleSidebar}
                toggled={toggled}
              />
            )}
            {user?.userType === "admin" && (
              <Item
                title="Blogs"
                to={`/blogs`}
                isActive={[
                  `/blogs`,
                  `/blogs/create`,
                  `/blogs/edit/${tempToggleId.length > 0 ? tempToggleId[tempToggleId[1] === "blogs" ? 3 : 2] : ""}`
                ]}
                icon={<RssFeedOutlined />}
                selected={selected}
                setSelected={setSelected}
                handleToggleSidebar={handleToggleSidebar}
                toggled={toggled}
              />
            )}
          </Box>

          {searchParams.get("manage") === "true" && (
            <Box>
              {/* {(user?.userType === "organizer" || user?.userType === "admin") && ( */}
              <Typography variant="h6" color={colors.grey[300]} sx={{ m: "15px 0 5px 20px" }}>
                Event:{" "}
                <Typography variant="span" fontWeight="bold">
                  #{tempToggleId.length > 0 && tempToggleId[tempToggleId[1] === "event" ? 2 : 3]}
                </Typography>
              </Typography>
              {/* )} */}

              {(user?.userType === "organizer" || user?.userType === "admin") && (
                <Item
                  title="Reports"
                  to={`/event/${
                    tempToggleId.length > 0 ? tempToggleId[tempToggleId[1] === "event" ? 2 : 3] : ""
                  }/report?manage=true`}
                  isActive={[
                    `/event/${tempToggleId.length > 0 ? tempToggleId[tempToggleId[1] === "event" ? 2 : 3] : ""}/report`
                  ]}
                  icon={<ReportIconeNew />}
                  selected={selected}
                  setSelected={setSelected}
                  handleToggleSidebar={handleToggleSidebar}
                  toggled={toggled}
                />
              )}
              {/* {(user?.userType === "organizer" || user?.userType === "admin") && (
                <Item
                  title="Age Group Report"
                  to={`/event/${
                    tempToggleId.length > 0 ? tempToggleId[tempToggleId[1] === "event" ? 2 : 3] : ""
                  }/age-report?manage=true`}
                  isActive={[
                    `/event/${
                      tempToggleId.length > 0 ? tempToggleId[tempToggleId[1] === "event" ? 2 : 3] : ""
                    }/age-report`
                  ]}
                  icon={<AgeGroupIcon />}
                  selected={selected}
                  setSelected={setSelected}
                  handleToggleSidebar={handleToggleSidebar}
                  toggled={toggled}
                />
              )} */}

              {(user.userType === "organizer" || user?.userType === "admin") && (
                <Item
                  title="Ticket Report"
                  to={`/event/${
                    tempToggleId.length > 0 ? tempToggleId[tempToggleId[1] === "event" ? 2 : 3] : ""
                  }/ticket-report?manage=true`}
                  isActive={[
                    `/event/${
                      tempToggleId.length > 0 ? tempToggleId[tempToggleId[1] === "event" ? 2 : 3] : ""
                    }/ticket-report`
                  ]}
                  state={{ from: location }}
                  icon={<ReportTicket />}
                  selected={selected}
                  setSelected={setSelected}
                  handleToggleSidebar={handleToggleSidebar}
                  toggled={toggled}
                />
              )}
              {(user.userType === "organizer" || user?.userType === "admin") && (
                <Item
                  title="Check-In"
                  to={`/event/${
                    tempToggleId.length > 0 ? tempToggleId[tempToggleId[1] === "event" ? 2 : 3] : ""
                  }/checkin?manage=true`}
                  isActive={[
                    `/event/${
                      tempToggleId.length > 0 ? tempToggleId[tempToggleId[1] === "event" ? 2 : 3] : ""
                    }/checkin`,
                    `/event/${
                      tempToggleId.length > 0 ? tempToggleId[tempToggleId[1] === "event" ? 2 : 3] : ""
                    }/user-checkin`,
                    `/event/${
                      tempToggleId.length > 0 ? tempToggleId[tempToggleId[1] === "event" ? 2 : 3] : ""
                    }/user-checkout`
                  ]}
                  state={{ from: location }}
                  icon={<CheckSingleIcon />}
                  selected={selected}
                  setSelected={setSelected}
                  handleToggleSidebar={handleToggleSidebar}
                  toggled={toggled}
                />
              )}
              {(user?.userType === "organizer" ||
                user?.userType === "admin" ||
                user?.userType === "checker" ||
                CHECKER_USER) && (
                <Item
                  title="Check-In Report"
                  to={`/event/${
                    tempToggleId.length > 0 ? tempToggleId[tempToggleId[1] === "event" ? 2 : 3] : ""
                  }/checkin-report?manage=true`}
                  isActive={[
                    `/event/${
                      tempToggleId.length > 0 ? tempToggleId[tempToggleId[1] === "event" ? 2 : 3] : ""
                    }/checkin-report`
                  ]}
                  icon={<CheckInIcon />}
                  selected={selected}
                  setSelected={setSelected}
                  handleToggleSidebar={handleToggleSidebar}
                  toggled={toggled}
                />
              )}
              {(user?.userType === "organizer" || user?.userType === "admin") && (
                <Item
                  title="Window Ticket User"
                  to={`/event/${
                    tempToggleId.length > 0 ? tempToggleId[tempToggleId[1] === "event" ? 2 : 3] : ""
                  }/windowUser?manage=true`}
                  isActive={[
                    `/event/${
                      tempToggleId.length > 0 ? tempToggleId[tempToggleId[1] === "event" ? 2 : 3] : ""
                    }/windowUser`
                  ]}
                  icon={<Ticket />}
                  selected={selected}
                  setSelected={setSelected}
                  handleToggleSidebar={handleToggleSidebar}
                  toggled={toggled}
                />
              )}
              {/* {user?.userType !== "admin" && (
                <Item
                  title="Sell Window Ticket"
                  to={`/event/${
                    tempToggleId.length > 0 ? tempToggleId[tempToggleId[1] === "event" ? 2 : 3] : ""
                  }/bookTicket?manage=true`}
                  isActive={[
                    `/event/${
                      tempToggleId.length > 0 ? tempToggleId[tempToggleId[1] === "event" ? 2 : 3] : ""
                    }/bookTicket`
                  ]}
                  state={{ from: location }}
                  icon={<TicketSell />}
                  selected={selected}
                  setSelected={setSelected}
                  handleToggleSidebar={handleToggleSidebar}
                  toggled={toggled}
                />
              )} */}
              {(user?.userType === "admin" || WINDOW_USER) && (
                <Item
                  title="Window Ticket"
                  to={`/event/${
                    tempToggleId.length > 0 ? tempToggleId[tempToggleId[1] === "event" ? 2 : 3] : ""
                  }/ticket-list?manage=true`}
                  isActive={[
                    `/event/${
                      tempToggleId.length > 0 ? tempToggleId[tempToggleId[1] === "event" ? 2 : 3] : ""
                    }/ticket-list`,
                    `/event/${
                      tempToggleId.length > 0 ? tempToggleId[tempToggleId[1] === "event" ? 2 : 3] : ""
                    }/bookTicket`
                  ]}
                  state={{ from: location }}
                  icon={<TicketSell />}
                  selected={selected}
                  setSelected={setSelected}
                  handleToggleSidebar={handleToggleSidebar}
                  toggled={toggled}
                />
              )}
              {(user?.userType === "organizer" || user?.userType === "admin") && (
                <Item
                  title="Sponsor/Promoter"
                  to={`/event/${
                    tempToggleId.length > 0 ? tempToggleId[tempToggleId[1] === "event" ? 2 : 3] : ""
                  }/sponser?manage=true`}
                  isActive={[
                    `/event/${
                      tempToggleId.length > 0 ? tempToggleId[tempToggleId[1] === "event" ? 2 : 3] : ""
                    }/sponser`,
                    `/event/${
                      tempToggleId.length > 0 ? tempToggleId[tempToggleId[1] === "event" ? 2 : 3] : ""
                    }/sponser/add`,
                    `/event/${
                      tempToggleId.length > 0 ? tempToggleId[tempToggleId[1] === "event" ? 2 : 3] : ""
                    }/sponser/add/${
                      tempToggleId.length > 0
                        ? tempToggleId[
                            tempToggleId[1] === "event" && tempToggleId[3] === "sponser" && tempToggleId[4] === "add"
                              ? 5
                              : 2
                          ]
                        : ""
                    }`,
                    `/event/${
                      tempToggleId.length > 0 ? tempToggleId[tempToggleId[1] === "event" ? 2 : 3] : ""
                    }/sponser/${
                      tempToggleId.length > 0
                        ? tempToggleId[tempToggleId[1] === "event" && tempToggleId[3] === "sponser" ? 4 : 2]
                        : ""
                    }`
                  ]}
                  state={{ from: location }}
                  icon={<SponsorIcon />}
                  selected={selected}
                  setSelected={setSelected}
                  handleToggleSidebar={handleToggleSidebar}
                  toggled={toggled}
                />
              )}

              {(user?.userType === "organizer" || user?.userType === "admin") && (
                <Item
                  title="Promo/Discount"
                  to={`/event/${
                    tempToggleId.length > 0 ? tempToggleId[tempToggleId[1] === "event" ? 2 : 3] : ""
                  }/discount?manage=true`}
                  isActive={[
                    `/event/${
                      tempToggleId.length > 0 ? tempToggleId[tempToggleId[1] === "event" ? 2 : 3] : ""
                    }/discount`,
                    `/event/${
                      tempToggleId.length > 0 ? tempToggleId[tempToggleId[1] === "event" ? 2 : 3] : ""
                    }/discount/add`,
                    `/event/${
                      tempToggleId.length > 0 ? tempToggleId[tempToggleId[1] === "event" ? 2 : 3] : ""
                    }/discount/add/${
                      tempToggleId.length > 0
                        ? tempToggleId[
                            tempToggleId[1] === "event" && tempToggleId[3] === "sponser" && tempToggleId[4] === "add"
                              ? 5
                              : 2
                          ]
                        : ""
                    }`,
                    `/event/${
                      tempToggleId.length > 0 ? tempToggleId[tempToggleId[1] === "event" ? 2 : 3] : ""
                    }/discount/${
                      tempToggleId.length > 0
                        ? tempToggleId[tempToggleId[1] === "event" && tempToggleId[3] === "discount" ? 4 : 2]
                        : ""
                    }`
                  ]}
                  state={{ from: location }}
                  icon={<DiscountIcon />}
                  selected={selected}
                  setSelected={setSelected}
                  handleToggleSidebar={handleToggleSidebar}
                  toggled={toggled}
                />
              )}
              {(user?.userType === "organizer" || user?.userType === "admin") && (
                <Item
                  title="Settlement "
                  to={`/event/${
                    tempToggleId.length > 0 ? tempToggleId[tempToggleId[1] === "event" ? 2 : 3] : ""
                  }/payment?manage=true`}
                  isActive={[
                    `/event/${
                      tempToggleId.length > 0 ? tempToggleId[tempToggleId[1] === "event" ? 2 : 3] : ""
                    }/payment`,
                    `/event/${
                      tempToggleId.length > 0 ? tempToggleId[tempToggleId[1] === "event" ? 2 : 3] : ""
                    }/payment/add`,
                    `/event/${
                      tempToggleId.length > 0 ? tempToggleId[tempToggleId[1] === "event" ? 2 : 3] : ""
                    }/payment/add/${
                      tempToggleId.length > 0
                        ? tempToggleId[
                            tempToggleId[1] === "event" && tempToggleId[3] === "payment" && tempToggleId[4] === "add"
                              ? 5
                              : 2
                          ]
                        : ""
                    }`
                    // `/event/${
                    //   tempToggleId.length > 0 ? tempToggleId[tempToggleId[1] === "event" ? 2 : 3] : ""
                    // }/sponser/${
                    //   tempToggleId.length > 0
                    //     ? tempToggleId[tempToggleId[1] === "event" && tempToggleId[3] === "sponser" ? 4 : 2]
                    //     : ""
                    // }`
                  ]}
                  state={{ from: location }}
                  icon={<BankIcon />}
                  selected={selected}
                  setSelected={setSelected}
                  handleToggleSidebar={handleToggleSidebar}
                  toggled={toggled}
                />
              )}
              {/* {(user?.userType === "organizer" || user?.userType === "admin") && (
                <Item
                  title="Bills"
                  to={`/event/${
                    tempToggleId.length > 0 ? tempToggleId[tempToggleId[1] === "event" ? 2 : 3] : ""
                  }/bills?manage=true`}
                  isActive={[
                    `/event/${tempToggleId.length > 0 ? tempToggleId[tempToggleId[1] === "event" ? 2 : 3] : ""}/bills`
                  ]}
                  state={{ from: location }}
                  icon={<BankIcon />}
                  selected={selected}
                  setSelected={setSelected}
                  handleToggleSidebar={handleToggleSidebar}
                  toggled={toggled}
                />
              )} */}
              {user?.userType === "admin" && (
                <Item
                  title="Activity Log"
                  to={`/event/${
                    tempToggleId.length > 0 ? tempToggleId[tempToggleId[1] === "event" ? 2 : 3] : ""
                  }/activity-log?manage=true`}
                  isActive={[
                    `/event/${
                      tempToggleId.length > 0 ? tempToggleId[tempToggleId[1] === "event" ? 2 : 3] : ""
                    }/activity-log`
                  ]}
                  icon={<Activity />}
                  selected={selected}
                  setSelected={setSelected}
                  handleToggleSidebar={handleToggleSidebar}
                  toggled={toggled}
                />
              )}
            </Box>
          )}
        </Menu>
      </ProSidebar>
    </Box>
  );
};

export default Sidebar;
