import { useTheme } from "@emotion/react";
import { FormHelperText } from "@mui/material";
import React from "react";
import { tokens } from "../theme";

export default function ErrorMSG({ text, sx = {} }) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return <FormHelperText sx={{ color: colors.danger[900], marginLeft: 0, ...sx }}>{text}</FormHelperText>;
}
