import { autoForm } from "./type";

// Artists intial state
const initialAuthState = {
  formList: null,
  isLoading: true,
  error: null,
  processLoading: false
};

export const reducer = (state = initialAuthState, action) => {
  switch (action.type) {
    case autoForm.GET_AUTO_FORM_REQUEST:
      return { ...state, isLoading: true };
    case autoForm.GET_AUTO_FORM_SUCCESS:
      return { ...state, isLoading: false, formList: action.payload.data };
    case autoForm.GET_AUTO_FORM_FAIL:
      return { ...state, isLoading: false, formList: null };
    case autoForm.ADD_AUTO_REQUEST:
      return { ...state, processLoading: true };
    case autoForm.ADD_AUTO_SUCCESS:
      return { ...state, processLoading: false };
    case autoForm.ADD_AUTO_FAIL:
      return { ...state, processLoading: false };
    case autoForm.DELETE_AUTO_REQUEST:
      return { ...state };
    case autoForm.DELETE_AUTO_SUCCESS:
      return { ...state };
    case autoForm.DELETE_AUTO_FAIL:
      return { ...state };
    case autoForm.UPDATE_AUTO_REQUEST:
      return { ...state };
    case autoForm.UPDATE_AUTO_SUCCESS:
      return { ...state };
    case autoForm.UPDATE_AUTO_FAIL:
      return { ...state };
    default:
      return state;
  }
};
