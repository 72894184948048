export const InvoiceType = {
  GET_INVOICE_REQUEST: "GET_LIST_REQUEST",
  GET_INVOICE_SUCCESS: "GET_ARTISTS_LIST_SUCCESS",
  GET_INVOICE_FAIL: "GET_ARTISTS_LIST_FAIL",
  GET_SERVICES_REQUEST: "GET_SERVICES_REQUEST",
  GET_SERVICES_SUCCESS: "GET_SERVICES_SUCCESS",
  GET_SERVICES_FAIL: "GET_SERVICES_FAIL",
  ADD_INVOICE_REQUEST: "ADD_INVOICE_REQUEST",
  ADD_INVOICE_SUCCESS: "ADD_INVOICE_SUCCESS",
  ADD_INVOICE_FAIL: "ADD_INVOICE_FAIL",
  GET_VIEW_INVOICE_REQUEST: "GET_VIEW_INVOICE_REQUEST",
  GET_VIEW_INVOICE_SUCCESS: "GET_VIEW_INVOICE_SUCCESS",
  GET_VIEW_INVOICE_FAIL: "GET_VIEW_INVOICE_FAIL",
  DONE_INVOICE_REQUEST: "DONE_INVOICE_REQUEST",
  DONE_INVOICE_SUCCESS: "DONE_INVOICE_SUCCESS",
  DONE_INVOICE_FAIL: "DONE_INVOICE_FAIL",
  ADD_SERVICES_REQUEST: "ADD_SERVICES_REQUEST",
  ADD_SERVICES_SUCCESS: "ADD_SERVICES_SUCCESS",
  ADD_SERVICES_FAIL: "ADD_SERVICES_FAIL",
  ADD_RECEIPT_REQUEST: "ADD_RECEIPT_REQUEST",
  ADD_RECEIPT_SUCCESS: "ADD_RECEIPT_SUCCESS",
  ADD_RECEIPT_FAIL: "ADD_RECEIPT_FAIL",
  DELETE_SERVICES_REQUEST: "DELETE_SERVICES_REQUEST",
  DELETE_SERVICES_SUCCESS: "DELETE_SERVICES_SUCCESS",
  DELETE_SERVICES_FAIL: "DELETE_SERVICES_FAIL",
  CONFIRM_RECEIPT_REQUEST: "CONFIRM_RECEIPT_REQUEST",
  CONFIRM_RECEIPT_SUCCESS: "CONFIRM_RECEIPT_SUCCESS",
  CONFIRM_RECEIPT_FAIL: "CONFIRM_RECEIPT_FAIL",
  DELETE_INVOICE_REQUEST: "DELETE_INVOICE_REQUEST",
  DELETE_INVOICE_SUCCESS: "DELETE_INVOICE_SUCCESS",
  DELETE_INVOICE_FAIL: "DELETE_INVOICE_FAIL"
};
