import { InvoiceType } from "./type";

// Artists intial state
const initialAuthState = {
  invoiceList: null,
  invoiceListLoading: false,
  servicesLoading: false,
  receiptLoading: false,
  services: null,
  error: null,
  addLoading: false,
  invoice: null,
  confirmLoading: false
};

export const reducer = (state = initialAuthState, action) => {
  switch (action.type) {
    case InvoiceType.GET_INVOICE_REQUEST:
      return { ...state, invoiceListLoading: true };
    case InvoiceType.GET_INVOICE_SUCCESS:
      return { ...state, invoiceListLoading: false, invoiceList: action.payload.data };
    case InvoiceType.GET_INVOICE_FAIL:
      return { ...state, invoiceListLoading: false, error: action.error };
    case InvoiceType.ADD_INVOICE_REQUEST:
      return { ...state, addLoading: true };
    case InvoiceType.ADD_INVOICE_SUCCESS:
      return { ...state, addLoading: false };
    case InvoiceType.ADD_INVOICE_FAIL:
      return { ...state, addLoading: false, error: action.error };
    case InvoiceType.DELETE_INVOICE_REQUEST:
      return { ...state, invoiceListLoading: true };
    case InvoiceType.DELETE_INVOICE_SUCCESS:
      return { ...state, invoiceListLoading: false };
    case InvoiceType.DELETE_INVOICE_FAIL:
      return { ...state, invoiceListLoading: false, error: action.error };
    case InvoiceType.GET_SERVICES_REQUEST:
      return { ...state, servicesLoading: true };
    case InvoiceType.GET_SERVICES_SUCCESS:
      return { ...state, servicesLoading: false, services: action.payload.data };
    case InvoiceType.GET_SERVICES_FAIL:
      return { ...state, servicesLoading: false, error: action.error, services: null };
    case InvoiceType.GET_VIEW_INVOICE_REQUEST:
      return { ...state, invoiceListLoading: true };
    case InvoiceType.GET_VIEW_INVOICE_SUCCESS:
      return { ...state, invoiceListLoading: false, invoice: action?.payload?.data };
    case InvoiceType.GET_VIEW_INVOICE_FAIL:
      return { ...state, invoiceListLoading: false, error: action.error, invoice: null };
    case InvoiceType.DONE_INVOICE_REQUEST:
      return { ...state, invoiceListLoading: true };
    case InvoiceType.DONE_INVOICE_SUCCESS:
      return { ...state, invoiceListLoading: false };
    case InvoiceType.DONE_INVOICE_FAIL:
      return { ...state, invoiceListLoading: false };
    case InvoiceType.ADD_SERVICES_REQUEST:
      return { ...state, servicesLoading: true };
    case InvoiceType.ADD_SERVICES_SUCCESS:
      return { ...state, servicesLoading: false };
    case InvoiceType.ADD_SERVICES_FAIL:
      return { ...state, servicesLoading: false };
    case InvoiceType.ADD_RECEIPT_REQUEST:
      return { ...state, receiptLoading: true };
    case InvoiceType.ADD_RECEIPT_SUCCESS:
      return { ...state, receiptLoading: false };
    case InvoiceType.ADD_RECEIPT_FAIL:
      return { ...state, receiptLoading: false };
    case InvoiceType.DELETE_SERVICES_REQUEST:
      return { ...state, servicesLoading: true };
    case InvoiceType.DELETE_SERVICES_SUCCESS:
      return { ...state, servicesLoading: false };
    case InvoiceType.DELETE_SERVICES_FAIL:
      return { ...state, servicesLoading: false };
    case InvoiceType.CONFIRM_RECEIPT_REQUEST:
      return { ...state, confirmLoading: true };
    case InvoiceType.CONFIRM_RECEIPT_SUCCESS:
      return { ...state, confirmLoading: false };
    case InvoiceType.CONFIRM_RECEIPT_FAIL:
      return { ...state, confirmLoading: false };
    default:
      return state;
  }
};
